import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Navigation} from 'swiper/modules';

const Projects = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const [currentImages, setCurrentImages] = useState([]);
    const [activeSlideIndices, setActiveSlideIndices] = useState({});

    const projects = [
        {
            id: 1,
            name: 'My Signature',
            dev: 'Front-End',
            images: [
                '/img/signatuur-0.png',
                '/img/signatuur-1.png',
            ],
            description: 'Simple website I made to showcase what I had learned as a "Concerned Technologist" in the first half of year 2. ' +
                'The content has a blurred effect and becomes clear when hovered over with the ' +
                'mouse, I chose this because I worked with people with a visual impairment during my first half year and I wanted the theme ' +
                'of the website to represent that.',
            link: 'https://mikkadg.github.io/mikes-signatuur/',
            view: 'Visit Website',
            skills: [
                {src: '/img/html-5.png', alt: 'HTML'},
                {src: '/img/css-3.png', alt: 'CSS'},
                {src: '/img/js.png', alt: 'JavaScript'},
                {src: '/img/tailwindcss.jpg', alt: 'TailwindCSS'},
            ],
        },
        {
            id: 2,
            name: 'Footballclubs DB',
            dev: 'Full-Stack',
            images: [
                '/img/fc-1.png',
                '/img/fc-2.png',
                '/img/fc-3.png',
                '/img/fc-4.png',
                '/img/fc-5.png',
                '/img/fc-6.png',
                '/img/fc-7.png',
                '/img/fc-8.png',
            ],
            description: 'A Full-Stack application with CRUD functionality and pagination. ' +
                'It\'s a database of footballclubs where you can add, edit, delete and view the clubs. ' +
                'You can also use the search bar and filters to search for specific clubs. ' +
                'Unfortunately, this application is not hosted anywhere, but you can watch a demo on YouTube.',
            link: 'https://youtu.be/Iqwg5-MNWPQ?si=6joofY1GUhUU0tjc',
            view: 'Watch YouTube Demo',
            skills: [
                {src: '/img/html-5.png', alt: 'HTML'},
                {src: '/img/css-3.png', alt: 'CSS'},
                {src: '/img/js.png', alt: 'JavaScript'},
                {src: '/img/react.svg', alt: 'React'},
                {src: '/img/tailwindcss.jpg', alt: 'TailwindCSS'},
                {src: '/img/mongo-db.png', alt: 'MongoDB'},
            ],
        },
        {
            id: 3,
            name: 'Handtracking Game',
            dev: 'Game | Machine Learning',
            images: [
                '/img/htgame-1.png',
                '/img/htgame-2.png',
                '/img/htgame-3.png',
                '/img/htgame-4.png',
                '/img/htgame-5.png',
            ],
            description: 'A simple platform game made with P5 that uses your webcam to control the player with your hand. ' +
                'The game uses the Handpose model from MediaPipe to detect your hand and then compares it to a model ' +
                'I trained with posedata and a Neural Network. ' +
                'Known issue: unintentional jumps when holding out your fist. (Game not supported on mobile devices)',
            link: 'https://prg8-prediction.vercel.app',
            view: 'Play Game',
            skills: [
                {src: '/img/html-5.png', alt: 'HTML'},
                {src: '/img/css-3.png', alt: 'CSS'},
                {src: '/img/js.png', alt: 'JavaScript'},
                {src: '/img/tailwindcss.jpg', alt: 'TailwindCSS'},
            ],
        },
        {
            id: 4,
            name: 'Guess That Pokémon',
            dev: 'Full-Stack | LLM',
            images: [
                '/img/pkmn-1.png',
                '/img/pkmn-2.png',
                '/img/pkmn-3.png',
                '/img/pkmn-4.png',
                '/img/pkmn-5.png',
                '/img/pkmn-6.png',
                '/img/pkmn-7.png',
                '/img/pkmn-8.png',
                '/img/pkmn-9.png',
            ],
            description: 'A guessing game that picks a random Pokémon from the PokéAPI and then gives ' +
                'this name in a prompt to the OpenAI Azure API to generate a description. ' +
                'You can change the personality of the AI to generate different descriptions. ' +
                'There is also an option to play the image game mode where you have to guess the Pokémon name based on ' +
                'the image. <br> ',
            link: 'https://guess-that-pokemon-tzl9z.ondigitalocean.app/',
            view: 'Play Game',
            skills: [
                {src: '/img/html-5.png', alt: 'HTML'},
                {src: '/img/css-3.png', alt: 'CSS'},
                {src: '/img/js.png', alt: 'JavaScript'},
                {src: '/img/tailwindcss.jpg', alt: 'TailwindCSS'},
            ],
        },
        // Add more projects as needed
    ];


    const openModal = (projectId, images) => {
        setCurrentImages(images);
        setActiveSlide(activeSlideIndices[projectId] || 0);
        setModalOpen(true);
    };


    return (
        <div className="flex flex-wrap justify-evenly items-center bg-gray-100 m-auto px-0 sm:px-10 md:px-20 lg:px-40"
             id="projects">
            <section className="py-20 bg-gray-100">
                <div className="container mx-auto px-4">
                    <h2 className="sm:text-2xl text-lg font-bold text-center text-purple-400">PROJECTS</h2>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 mt-8">
                        {projects.map((project) => (
                            <div key={project.id} className="bg-white rounded-lg shadow-lg">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    navigation={true}
                                    modules={[Navigation]}
                                    initialSlide={activeSlide}
                                    className="rounded-lg"
                                    onSlideChange={(swiper) => setActiveSlideIndices(prevIndices => ({
                                        ...prevIndices,
                                        [project.id]: swiper.realIndex,
                                    }))}
                                    onClick={() => openModal(project.id, project.images)}
                                    loop={true}
                                >
                                    {project.images.map((image, idx) => (
                                        <SwiperSlide key={idx}>
                                            <img src={image} alt={`Project ${idx + 1}`}
                                                 className="object-cover hover:cursor-pointer"/>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="p-4">
                                    <div className="flex justify-start items-center title">
                                        <h3 className="text-xl font-bold project-name">{project.name}</h3>
                                        <p className="text-sm font-bold text-gray-400 ml-2">{project.dev}</p>
                                        <div className="flex flex-row space-x-2 skills ml-2">
                                            {project.skills.map((skill, index) => (
                                                <img key={index} src={skill.src} alt={skill.alt}
                                                     className="w-6 h-6 rounded"/>
                                            ))}
                                        </div>
                                    </div>
                                    <p className="mt-2" dangerouslySetInnerHTML={{__html: project.description}}></p>
                                    <div className="mt-4 flex justify-around">
                                        <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-view font-bold py-2 px-4 z-10">
                                                {project.view}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {isModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center"
                    onClick={() => setModalOpen(false)} // This closes the modal when the overlay is clicked
                >
                    <div
                        className="bg-white p-5 rounded-lg max-w-5xl max-h-full overflow-auto"
                        onClick={(e) => e.stopPropagation()} // This prevents clicks inside the modal from closing it
                    >
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            navigation={true}
                            modules={[Navigation]}
                            initialSlide={activeSlide}
                            className="rounded-lg"
                            loop={true}
                        >
                            {currentImages.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`Project ${index + 1}`} className="object-cover rounded-lg"/>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <button onClick={() => setModalOpen(false)}
                                className="absolute top-5 right-5 text-3xl text-white hover:text-gray-400">
                            &times;
                        </button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Projects;