import {useState} from 'react';

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);

    const scrollToTop = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        closeMenu(); // Close menu when link is clicked
    };

    return (
        <header className="bg-white shadow-md fixed w-full" id="header">
            <nav className="mx-auto flex justify-between items-center p-4">
                <div>
                    <a href="#home" onClick={(e) => scrollToTop(e)}>
                        <img src="/img/favicon.png" alt="Logo" className="h-12"/>
                    </a>
                </div>
                <div className="hidden sm:flex">
                    <a href="#home" onClick={(e) => scrollToTop(e)}
                       className="px-4 py-2 font-bold text-black hover:bg-purple-200 rounded">HOME</a>
                    <a href="#about" onClick={closeMenu}
                       className="px-4 py-2 font-bold text-black hover:bg-purple-200 rounded">ABOUT ME</a>
                    <a href="#experience" onClick={closeMenu}
                       className="px-4 py-2 font-bold text-black hover:bg-purple-200 rounded">EXPERIENCE</a>
                    <a href="#projects" onClick={closeMenu}
                       className="px-4 py-2 font-bold text-black hover:bg-purple-200 rounded">PROJECTS</a>
                    <a href="#contact" onClick={closeMenu}
                       className="px-4 py-2 font-bold text-black hover:bg-purple-200 rounded">CONTACT</a>
                </div>
                <button className="sm:hidden" onClick={toggleMenu}>
                    MENU
                </button>
                {isMenuOpen && (
                    <div className="sm:hidden absolute top-full right-0 bg-white shadow-md">
                        <a href="#home" onClick={(e) => {
                            scrollToTop(e);
                            closeMenu();
                        }} className="block px-4 py-2 text-black hover:bg-purple-200">HOME</a>
                        <a href="#about" onClick={closeMenu} className="block px-4 py-2 text-black hover:bg-purple-200">ABOUT
                            ME</a>
                        <a href="#experience" onClick={closeMenu}
                           className="block px-4 py-2 text-black hover:bg-purple-200">EXPERIENCE</a>
                        <a href="#projects" onClick={closeMenu}
                           className="block px-4 py-2 text-black hover:bg-purple-200">PROJECTS</a>
                        <a href="#contact" onClick={closeMenu}
                           className="block px-4 py-2 text-black hover:bg-purple-200">CONTACT</a>
                    </div>
                )}
            </nav>
        </header>
    );
}
