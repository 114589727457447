import React, {useEffect} from 'react';

export default function AboutMe() {
    useEffect(() => {
        const updateCarouselAnimation = () => {
            const carousel = document.querySelector('.skills-carousel');
            const totalWidth = [...carousel.children].reduce((total, child) => total + child.offsetWidth + 20, 0); // 20px voor de margin

            const animationName = 'dynamic-scroll';
            const keyframes = `
                @keyframes ${animationName} {
                    from { transform: translateX(0); }
                    to { transform: translateX(-${totalWidth / 2}px); } // Aangezien de logos herhaald zijn, delen we door 2 voor een soepele loop
                }
            `;

            let styleTag = document.querySelector('style#dynamic-carousel-animation');
            if (!styleTag) {
                styleTag = document.createElement('style');
                styleTag.id = 'dynamic-carousel-animation';
                document.head.appendChild(styleTag);
            }

            styleTag.textContent = keyframes;
            carousel.style.animation = `${animationName} 10s linear infinite`;
        };

        updateCarouselAnimation();
        window.addEventListener('resize', updateCarouselAnimation);

        return () => {
            window.removeEventListener('resize', updateCarouselAnimation);
        };
    }, []);

    return (
        <div className="flex flex-wrap justify-evenly items-center bg-gray-100 h-screen m-auto" id="about">
            <section id="home-content" className="text-start md:w-2/3 sm:w-full pt-0 ">
                <div className="flex justify-around mx-auto flex-col md:flex-row items-center px-6 md:px-0">
                    <div className="flex flex-col md:w-2/3">
                        <h2 className="sm:text-2xl text-lg font-bold text-center text-purple-400">ABOUT ME</h2>
                        <p className="sm:text-xl mt-4 text-gray-700">
                            Hi, I'm Mike de Graaff, a 2nd year Creative Media and Game Technologies student at
                            Rotterdam University of Applied Sciences. <br/> I'm looking for an internship starting in
                            September 2024.<br/>
                            I would love to gain more experience in Front-End development and learn the ways of a
                            professional Front-End
                            developer in a team.

                        </p>
                    </div>
                    <div className="md:w-1/4 md:mt-0 mt-10">
                        <img src="/img/pfp.png" alt="Mikes pfp" className="bubble-wrapper"/>
                    </div>
                </div>
                <div className="mb-0 px-6">
                    <p className="sm:text-xl text-gray-700 mt-10 md:mt-28">
                        I am familiar with:
                    </p>

                    <div className="skills-carousel-wrapper mt-5">
                        <div className="skills-carousel">
                            <img src="/img/html-5.png" alt="HTML" className="skill-logo"/>
                            <img src="/img/css-3.png" alt="CSS" className="skill-logo"/>
                            <img src="/img/js.png" alt="JavaScript" className="skill-logo rounded"/>
                            <img src="/img/react.svg" alt="React" className="skill-logo rounded"/>
                            <img src="/img/tailwindcss.jpg" alt="TailwindCSS" className="skill-logo rounded"/>
                            <img src="/img/php.png" alt="PHP" className="skill-logo"/>
                            <img src="/img/mysql.png" alt="MySQL" className="skill-logo"/>
                            <img src="/img/mongo-db.png" alt="MongoDB" className="skill-logo"/>
                            {/*Repeat the logos again for a smooth looping effect*/}
                            <img src="/img/html-5.png" alt="HTML" className="skill-logo"/>
                            <img src="/img/css-3.png" alt="CSS" className="skill-logo"/>
                            <img src="/img/js.png" alt="JavaScript" className="skill-logo rounded"/>
                            <img src="/img/react.svg" alt="React" className="skill-logo rounded"/>
                            <img src="/img/tailwindcss.jpg" alt="TailwindCSS" className="skill-logo rounded"/>
                            <img src="/img/php.png" alt="PHP" className="skill-logo"/>
                            <img src="/img/mysql.png" alt="MySQL" className="skill-logo"/>
                            <img src="/img/mongo-db.png" alt="MongoDB" className="skill-logo"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
