import React from 'react';

export default function Experience() {
    return (
        <div id="experience" className="flex flex-col lg:flex-row flex-wrap justify-around items-center bg-white py-20 lg:h-screen m-auto md:px-32">
            <div className="w-full md:flex-1">
                <img src="/img/laptop.png" alt="Table with coffee, a notepad and a laptop" className="object-contain h-80 rounded-2xl mx-auto" />
            </div>

            <section className="w-full md:flex-1 bg-white">
                <div className="container mx-auto px-4">
                    <h2 className="sm:text-2xl text-lg font-bold text-center text-purple-400">EXPERIENCE</h2>
                    <h3 className="text-xl font-bold mt-8">Creative (Web) Developer based in Zoetermeer,
                        Netherlands</h3>
                    <p className="mt-4 text-lg text-gray-600">
                        As I've said before I'd love to learn more about Front-End but I also have some experience with
                        Back-End,
                        Full-Stack, and even a tiny bit of Game Development from school projects. If I can find a match
                        with a company
                        in any of these branches that would be great too. <br/>
                        I'm always eager to learn new things and improve my skills!
                    </p>
                </div>
            </section>
        </div>
    );
}
