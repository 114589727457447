import Header from './components/Header.js';
import AboutMe from './components/AboutMe';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';
import Home from './components/Home';

function App() {
    return (
        <div id="home">
            <Home/>
            <div id="content">
                <Header/>
                <AboutMe/>
                <Experience/>
                <Projects/>
                <Contact/>
            </div>
        </div>
    );
}

export default App;
