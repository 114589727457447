export default function Contact() {
    return (
        <section id="contact" className="py-10 bg-black">
            <div className="container mx-auto px-4 flex justify-around">
                <div className="max-w-48 sm:max-w-full">
                    <h3 className="sm:text-xl font-bold text-center text-white">
                        © 2024 Mike de Graaff. All rights reserved.
                    </h3>
                </div>
                <div className="flex">
                    <div className="tooltip">
                        <a href="mailto:mike-de-graaff@hotmail.com" target="_blank" rel="noopener noreferrer">
                            <img src="/img/mail.png" alt="Mail" className="w-8 h-8"/>
                        </a>
                        <span className="tooltip-text">Email Me</span>
                    </div>
                    <div className="tooltip ml-4">
                        <a href="https://www.linkedin.com/in/mike-de-graaff-7734181a1/" target="_blank" rel="noopener noreferrer">
                            <img src="/img/linkedin-logo.png" alt="LinkedIn" className="w-8 h-8"/>
                        </a>
                        <span className="tooltip-text">LinkedIn Profile</span>
                    </div>
                    <div className="tooltip ml-4">
                        <a href="https://github.com/MikkaDG" target="_blank" rel="noopener noreferrer">
                            <img src="/img/github-icon.png" alt="GitHub" className="w-8 h-8"/>
                        </a>
                        <span className="tooltip-text">GitHub Profile</span>
                    </div>
                </div>
            </div>
        </section>
    );
}
