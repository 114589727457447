import React, { useEffect, useState } from 'react';

const Star = ({ style }) => (
    <div className="star" style={style}></div>
);

const Home = () => {
    const [stars, setStars] = useState([]);

    useEffect(() => {
        const newStars = [];
        for (let i = 0; i < 100; i++) { // Generate 100 stars
            const style = {
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDuration: `${Math.random() * 2 + 1}s`, // Randomize animation duration
                animationDelay: `${Math.random() * 2}s`, // Randomize animation delay
            };
            newStars.push(<Star key={i} style={style} />);
        }
        setStars(newStars);
    }, []);

    return (
        <div id="attention-grabber"
             className="flex flex-col flex-wrap justify-around items-center bg-black py-20 lg:h-screen m-auto md:px-32 relative">
            {stars}
            <h1 className="welcome-name text-4xl z-10">MIKE DE GRAAFF</h1>
            <a className="font-bold py-2 px-4 about-button z-10"
               href="#about">
                LEARN MORE ABOUT ME
            </a>
        </div>
    );
};

export default Home;
